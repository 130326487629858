import {
  CellId,
  DELETE_CELL,
  HexVersionAtomicOperation,
  HexVersionId,
  MOVE_CELL,
  UPDATE_CELL,
  getIndicesBetween,
} from "@hex/common";

import { hexVersionMPSelectors } from "../../../../redux/slices/hexVersionMPSlice";
import { RootState } from "../../../../redux/store.js";

/**
 * Helper to get the AO operations needed to eject a block cell
 */
export function getEjectBlockCellOps(
  cellId: CellId,
  hexVersionId: HexVersionId,
  state: RootState,
): HexVersionAtomicOperation[] {
  const cell = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectById(state, cellId);

  if (cell?.blockCellId == null) {
    throw new Error("could not get block cell");
  }

  const blockCellContents = hexVersionMPSelectors
    .getCellContentSelectors(hexVersionId)
    .selectByCellId(state, cellId);

  if (blockCellContents?.__typename !== "BlockCell") {
    throw new Error("expected block cell");
  }

  const allSortedCells = hexVersionMPSelectors
    .getCellSelectors(hexVersionId)
    .selectFlattenedSorted(state);

  const sortedSameLevelCells = allSortedCells.filter(
    (c) => c.parentCellId === cell.parentCellId,
  );
  const sortedChildCells = allSortedCells.filter(
    (c) => c.parentCellId === cellId,
  );

  const parentIndex = sortedSameLevelCells.findIndex(
    (c) => c.id === blockCellContents.cellId,
  );

  const insertAfter = sortedSameLevelCells[parentIndex - 1]?.order ?? undefined;
  const insertBefore =
    sortedSameLevelCells[parentIndex + 1]?.order ?? undefined;

  const orders = getIndicesBetween({
    start: insertAfter,
    end: insertBefore,
    count: sortedChildCells.length,
  });

  return [
    ...sortedChildCells.flatMap((childCell, idx) => {
      return [
        UPDATE_CELL.create(childCell.id, "parentBlockCellId", null),
        MOVE_CELL.create({
          cellId: childCell.id,
          parentCellId: cell.parentCellId ?? undefined,
          insertAt: orders[idx],
          insertAfter: undefined,
          insertBefore: undefined,
        }),
      ];
    }),
    DELETE_CELL.create({ cellId, skipToaster: true }),
  ];
}
