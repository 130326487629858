import { Callout, CalloutProps, Classes } from "@blueprintjs/core";
import { rgba } from "polished";
import styled, { css } from "styled-components";

export type HexCalloutProps = CalloutProps & {
  $fade?: boolean;
  $noBackground?: boolean;
  $size?: "small" | "default";
};

const smallStyles = css`
  && {
    padding-top: 7px;
    padding-bottom: 7px;

    font-size: ${({ theme }) => theme.fontSize.SMALL};
    line-height: 16px;

    /* Applied if an icon exists */
    &.${Classes.CALLOUT_ICON} {
      padding-left: 30px;

      > .${Classes.ICON}:first-child {
        top: 7px;
        left: 8px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    > .${Classes.HEADING} {
      width: auto;
      margin: 0 5px 0 0;

      font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};

      font-size: ${({ theme }) => theme.fontSize.SMALL};
      line-height: 16px;
    }
  }
`;

/**
 * https://blueprintjs.com/docs/#core/components/callout
 */
export const HexCallout = styled(Callout)<HexCalloutProps>`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};

  padding: 12px;

  color: ${({ theme }) => theme.fontColor.DEFAULT};

  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  text-transform: none;

  background-color: ${({ theme }) => theme.CalloutOrToastBackground.none};

  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.borderColor.DEFAULT};

  ${({ $fade }) => ($fade ? "opacity: 0.45" : "")};

  > .${Classes.HEADING} {
    width: 100%;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    font-size: ${({ theme }) => theme.fontSize.DEFAULT};
  }

  &.${Classes.CALLOUT_ICON} {
    > .${Classes.ICON} {
      &:first-child {
        top: 12px;
        left: 12px;

        color: ${({ theme }) => theme.iconColor};
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  /* INTENT PRIMARY */
  &.${Classes.INTENT_PRIMARY} {
    background-color: ${({ $noBackground, theme }) =>
      $noBackground
        ? theme.backgroundColor.DEFAULT
        : theme.CalloutOrToastBackground.primary};
    color: ${({ theme }) => theme.fontColor.DEFAULT};

    box-shadow: inset 0 0 0 1px
      ${({ theme }) => rgba(theme.intent.PRIMARY, 0.5)};

    > .${Classes.HEADING} {
      color: ${({ theme }) => theme.fontColor.DEFAULT};
    }

    &.${Classes.CALLOUT_ICON} {
      > .${Classes.ICON}:first-child > svg {
        color: ${({ theme }) => theme.intent.PRIMARY};
      }
    }
  }

  /* INTENT SUCCESS */
  &.${Classes.INTENT_SUCCESS} {
    background-color: ${({ $noBackground, theme }) =>
      $noBackground
        ? theme.backgroundColor.DEFAULT
        : theme.CalloutOrToastBackground.success};
    border-left: 3px solid ${({ theme }) => theme.intent.SUCCESS};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.intent.SUCCESS};
    color: ${({ theme }) => theme.fontColor.DEFAULT};

    > .${Classes.HEADING} {
      color: ${({ theme }) => theme.fontColor.DEFAULT};
    }

    &.${Classes.CALLOUT_ICON} {
      > .${Classes.ICON} {
        &:first-child > svg {
          color: ${({ theme }) => theme.intent.SUCCESS};
        }
      }
    }
  }

  /* INTENT WARNING */
  &.${Classes.INTENT_WARNING} {
    background-color: ${({ $noBackground, theme }) =>
      $noBackground
        ? theme.backgroundColor.DEFAULT
        : theme.CalloutOrToastBackground.warning};
    color: ${({ theme }) => theme.fontColor.DEFAULT};

    box-shadow: inset 0 0 0 1px
      ${({ theme }) => rgba(theme.intent.WARNING, 0.5)};

    > .${Classes.HEADING} {
      color: ${({ theme }) => theme.fontColor.DEFAULT};
    }

    &.${Classes.CALLOUT_ICON} {
      > .${Classes.ICON} {
        &:first-child > svg {
          color: ${({ theme }) => theme.intent.WARNING};
        }
      }
    }
  }

  /* INTENT DANGER */
  &.${Classes.INTENT_DANGER} {
    background-color: ${({ $noBackground, theme }) =>
      $noBackground
        ? theme.backgroundColor.DEFAULT
        : theme.CalloutOrToastBackground.danger};
    color: ${({ theme }) => theme.fontColor.DEFAULT};

    box-shadow: inset 0 0 0 1px ${({ theme }) => rgba(theme.intent.DANGER, 0.5)};

    > .${Classes.HEADING} {
      color: ${({ theme }) => theme.fontColor.DEFAULT};
    }

    &.${Classes.CALLOUT_ICON} {
      > .${Classes.ICON} {
        &:first-child > svg {
          color: ${({ theme }) => theme.intent.DANGER};
        }
      }
    }
  }

  ${({ $size }) => ($size === "small" ? smallStyles : "")}
`;
