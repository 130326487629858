import { CellId, HexId, stableEmptyObject } from "@hex/common";
import { useCallback } from "react";

import { ComponentVersionStub } from "../../generated/graphqlTypes";
import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  CellContentsMP,
  CellMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";

export interface UseImportedComponentsSelectorArgs<T> {
  selector: (componentVersionStub: ComponentVersionStub[]) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useImportedComponentsSelector<T>({
  equalityFn,
  selector,
}: UseImportedComponentsSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const componentIds = hexVersionMPSelectors
      .getSharedComponentSelectors(hexVersionId)
      .selectImportedComponentVersions(state);

    return selector(componentIds ?? []);
  }, equalityFn);
}

export interface ImportedComponentIdsGetterArgs<A extends unknown[], T> {
  selector?: (importedComponentIds: Set<HexId>, ...args: A) => T;
}

export type ImportedComponentIdsGetterResult<A extends unknown[], T> = (
  ...args: A
) => T;

export function useImportedComponentIdsGetter<
  A extends unknown[],
  T = Set<HexId>,
>({
  selector,
}: ImportedComponentIdsGetterArgs<A, T> = {}): ImportedComponentIdsGetterResult<
  A,
  T
> {
  const { hexVersionId } = useProjectContext();
  const store = useStore();

  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const importedComponentVersions = hexVersionMPSelectors
        .getSharedComponentSelectors(hexVersionId)
        .selectImportedComponentVersions(store.getState());

      if (importedComponentVersions == null) {
        throw new Error(
          `Missing imported component versions for hex version: ${hexVersionId}`,
        );
      }

      const componentIds = new Set(
        importedComponentVersions.map((stub) => stub.componentId),
      );
      return selectorRef.current
        ? selectorRef.current(componentIds, ...args)
        : (componentIds as unknown as T);
    },
    [hexVersionId, store, selectorRef],
  );
}

export interface UseImportedComponentCellsSelectorArgs<T> {
  componentImportCellId: string | undefined;
  selector: (cells: Record<CellId, CellMP>) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useImportedComponentCellsSelector<T>({
  componentImportCellId,
  equalityFn,
  selector,
}: UseImportedComponentCellsSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const cells = hexVersionMPSelectors
      .getSharedComponentSelectors(hexVersionId)
      .getSelectChildCellsSelector(componentImportCellId);

    if (cells == null) {
      throw new Error(
        `Missing cells state for component: ${componentImportCellId}`,
      );
    }

    return selector(cells(state) ?? {});
  }, equalityFn);
}

interface ImportedComponentCellsGetterArgs<A extends unknown[], T> {
  selector?: (cells: Record<CellId, CellMP>, ...args: A) => T;
}

type ImportedComponentCellsGetterResult<A extends unknown[], T> = (
  componentImportCellId: string,
  ...args: A
) => T;

export function useImportedComponentCellsGetter<
  A extends unknown[],
  T = Record<CellId, CellMP>,
>({
  selector,
}: ImportedComponentCellsGetterArgs<
  A,
  T
> = {}): ImportedComponentCellsGetterResult<A, T> {
  const { hexVersionId } = useProjectContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (componentImportCellId: string, ...args: A) => {
      const cells = hexVersionMPSelectors
        .getSharedComponentSelectors(hexVersionId)
        .getSelectChildCellsSelector(componentImportCellId)(store.getState());

      if (cells == null) {
        throw new Error(
          `Missing cells state for component: ${componentImportCellId}`,
        );
      }
      return selectorRef.current
        ? selectorRef.current(cells, ...args)
        : (cells as T);
    },
    [hexVersionId, selectorRef, store],
  );
}

export interface UseImportedComponentCellsContentsSelectorArgs<T> {
  cellIds: CellId[];
  selector: (cells: Record<CellId, CellContentsMP>) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useImportedComponentCellsContentsSelector<T>({
  cellIds,
  equalityFn,
  selector,
}: UseImportedComponentCellsContentsSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const cellContents = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectByCellIds(state, cellIds);

    return selector(cellContents ?? stableEmptyObject());
  }, equalityFn);
}

interface ImportedComponentCellsContentsGetterArgs<A extends unknown[], T> {
  selector?: (cells: Record<CellId, CellContentsMP>, ...args: A) => T;
}

type ImportedComponentCellsContentsGetterResult<A extends unknown[], T> = (
  cellIds: CellId[],
  ...args: A
) => T;

export function useImportedComponentCellsContentsGetter<
  A extends unknown[],
  T = Record<CellId, CellContentsMP>,
>({
  selector,
}: ImportedComponentCellsContentsGetterArgs<
  A,
  T
> = {}): ImportedComponentCellsContentsGetterResult<A, T> {
  const { hexVersionId } = useProjectContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (cellIds: CellId[], ...args: A) => {
      const cellContents = hexVersionMPSelectors
        .getCellContentSelectors(hexVersionId)
        .selectByCellIds(store.getState(), cellIds);

      return selectorRef.current
        ? selectorRef.current(cellContents ?? stableEmptyObject(), ...args)
        : (cellContents as T);
    },
    [hexVersionId, selectorRef, store],
  );
}
