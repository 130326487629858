import { Classes, NonIdealState, NonIdealStateProps } from "@blueprintjs/core";
import styled, { css } from "styled-components";

const SmallStyles = css`
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;

  .${Classes.NON_IDEAL_STATE_VISUAL} {
    margin-bottom: 10px;
  }

  .${Classes.HEADING} {
    margin-bottom: 5px;

    font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};

    font-size: ${({ theme }) => theme.fontSize.SMALL};
    line-height: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MinimalStyles = css`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  padding: 7px 8px;

  font-size: ${({ theme }) => theme.fontSize.SMALL};

  border: 1px dotted ${({ theme }) => theme.borderColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};

  .${Classes.NON_IDEAL_STATE_VISUAL} {
    width: 20px;
    height: 20px;
    margin-bottom: 0;

    border: none;
    border-radius: 0;

    .${Classes.ICON} {
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;
        fill-opacity: 1;
        stroke-width: 0;
        stroke-opacity: 1;

        path {
          stroke-opacity: 1;
          stroke-width: 0;
        }
      }
    }
  }

  .${Classes.HEADING} {
    margin: 0;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-weight: ${({ theme }) => theme.fontWeight.NORMAL};

    font-size: ${({ theme }) => theme.fontSize.SMALL};
    line-height: 16px;
    letter-spacing: 0;
  }
`;

export const HexNonIdealStateContainer = styled.div`
  display: flex;
  flex: auto;
  align-items: center;
  overflow: auto;
`;

export type HexNonIdealStateProps = NonIdealStateProps & {
  $small?: boolean;
  $minimal?: boolean;
  $noBorder?: boolean;
  $autoHeight?: boolean;
  $hasIllustration?: boolean;
};

export const HexNonIdealState = styled(NonIdealState)<HexNonIdealStateProps>`
  width: 100%;
  ${({ $autoHeight }) => ($autoHeight ? "height: auto;" : "height: 100%;")}
  padding: 30px;

  color: ${({ theme }) => theme.fontColor.MUTED};

  .${Classes.NON_IDEAL_STATE_VISUAL} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ $small }) => ($small ? "50px" : "70px")};
    height: ${({ $small }) => ($small ? "50px" : "70px")};
    margin-bottom: 15px;

    color: ${({ $hasIllustration, theme }) =>
      $hasIllustration ? theme.highlightColor : theme.iconColor};

    border: 1px solid ${({ theme }) => theme.borderColor.DEFAULT};
    border-radius: 50%;

    ${({ $hasIllustration }) =>
      $hasIllustration &&
      css`
        border: none;
        width: 240px;
        height: 240px;

        svg {
          height: 100%;
        }
      `}

    .${Classes.ICON} {
      width: ${({ $small }) => ($small ? "20px" : "40px")};
      height: ${({ $small }) => ($small ? "20px" : "40px")};

      /* stylelint has trouble with this block for some reason, so we wrap it */
      ${css`
        svg {
          width: 100%;
          height: 100%;
          fill-opacity: 1;
          stroke-width: 0;
          stroke-opacity: 1;

          path {
            stroke-opacity: 1;
            stroke-width: 0;
          }
        }
      `}
    }
  }

  .${Classes.HEADING} {
    margin-bottom: 12px;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
    font-size: ${({ theme }) => theme.fontSize.LARGE};
    letter-spacing: -0.3px;
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    padding: 0 40px;

    color: ${({ theme }) => theme.fontColor.MUTED};
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
    text-transform: uppercase;

    &:first-of-type {
      margin-top: 10px;
    }

    &::before,
    &::after {
      display: block;
      flex: 1 1 auto;
      height: 1px;
      margin: 0 10px 0 0;

      background: ${({ theme }) => theme.borderColor.DEFAULT};

      content: "";
    }

    &::after {
      margin: 0 0 0 10px;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${({ $small }) => $small && SmallStyles}
  ${({ $minimal }) => $minimal && MinimalStyles}
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      .${Classes.NON_IDEAL_STATE_VISUAL} {
        border: none !important;
      }
    `}
`;
