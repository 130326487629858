import { Classes } from "@blueprintjs/core";
import { rgba } from "polished";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

const IconWrapper = styled.span<{
  $color?: string;
}>`
  background-color: ${({ $color, theme }) =>
    rgba($color ?? theme.iconColor, 0.1)};
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 20px;
  .${Classes.ICON} {
    svg {
      width: 20px;
      height: 20px;
      color: ${({ $color }) => $color};
    }
  }
`;

export interface HexTitleIconProps extends ComponentPropsWithoutRef<"span"> {
  children: React.ReactNode;
  color?: string;
}

export const HexTitleIcon: React.ComponentType<HexTitleIconProps> = ({
  color,
  ...props
}) => <IconWrapper $color={color} {...props} />;
