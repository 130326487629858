import { IconType, typedObjectEntries } from "@hex/common";
import React, { useMemo } from "react";
import styled from "styled-components";

import {
  AddReactionIcon,
  BlessedIcon,
  BugIcon,
  CertifiedIcon,
  CheckboxInputIcon,
  ChexagonIcon,
  ClockIcon,
  ComputerChipIcon,
  EmailIcon,
  ExperimentIcon,
  FireIcon,
  FolderIcon,
  GemIcon,
  HeartIcon,
  KeyIcon,
  LightbulbIcon,
  LightningIcon,
  LockIcon,
  MedalIcon,
  MegaphoneIcon,
  NotificationUnreadIcon,
  PlusCircleIcon,
  QuickstartIcon,
  ReviewInProgressIcon,
  SparklesIcon,
  SuccessIcon,
  ThumbsUpIcon,
  TrophyIcon,
  UnfilledStarIcon,
  UnlockIcon,
} from "../components/icons/CustomIcons.js";

import { HexButton } from "./HexButton.js";
import { HexPopover } from "./HexPopover.js";

export const IconsToNames: Record<IconType, JSX.Element> = {
  chexagon: <ChexagonIcon />,
  certified: <CertifiedIcon />,
  trophy: <TrophyIcon />,
  heart: <HeartIcon />,
  medal: <MedalIcon />,
  computerchip: <ComputerChipIcon />,
  thumbsup: <ThumbsUpIcon />,
  blessed: <BlessedIcon />,
  experiment: <ExperimentIcon />,
  lightbulb: <LightbulbIcon />,
  megaphone: <MegaphoneIcon />,
  fire: <FireIcon />,
  pluscircle: <PlusCircleIcon />,
  checksquare: <CheckboxInputIcon />,
  notificationunread: <NotificationUnreadIcon />,
  bug: <BugIcon />,
  gem: <GemIcon />,
  private: <LockIcon />,
  unlock: <UnlockIcon />,
  success: <SuccessIcon />,
  reviewinprogress: <ReviewInProgressIcon />,
  addreaction: <AddReactionIcon />,
  quickstart: <QuickstartIcon />,
  envelope: <EmailIcon />,
  key: <KeyIcon />,
  sparkles: <SparklesIcon />,
  lightning: <LightningIcon />,
  star: <UnfilledStarIcon />,
  clock: <ClockIcon />,
  folder: <FolderIcon />,
};

interface IconPickerProps {
  selectedIcon: IconType | null;
  onPick: (icon: IconType) => void;
}

export const IconPicker: React.ComponentType<IconPickerProps> = React.memo(
  function IconPicker({ onPick, selectedIcon }) {
    const iconToShow = selectedIcon ?? "chexagon";

    const IconOptions = useMemo(
      () =>
        typedObjectEntries(IconsToNames).map(([name, icon]) => (
          <HexButton
            key={name}
            active={iconToShow === name}
            icon={icon}
            minimal={true}
            // eslint-disable-next-line react/jsx-no-bind -- passing icon name
            onClick={() => onPick(name)}
          />
        )),
      [iconToShow, onPick],
    );

    return (
      <HexPopover
        content={<OptionsWrapper>{IconOptions}</OptionsWrapper>}
        minimal={true}
        placement="bottom-start"
      >
        <HexButton icon={IconsToNames[iconToShow]} />
      </HexPopover>
    );
  },
);

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 4px;
  padding: 4px;
`;
