import {
  HexVersionAtomicOperation,
  HexVersionId,
  MagicAcceptType,
  MagicEventId,
  MagicEventStatus,
  MagicEventUpdatableFields,
  UPDATE_MAGIC_EVENT,
  notEmpty,
  typedObjectValues,
} from "@hex/common";

import {
  hexVersionMPActions,
  hexVersionMPSelectors,
} from "../../../redux/slices/hexVersionMPSlice.js";
import { AppStore, RootState } from "../../../redux/store.js";

export function updateMagicEvent({
  hexVersionId,
  key,
  magicEventId,
  store,
  value,
}: {
  hexVersionId: HexVersionId;
  magicEventId: MagicEventId;
  key: keyof MagicEventUpdatableFields;
  value: unknown;
  store: AppStore;
}): void {
  const magicEvent = hexVersionMPSelectors
    .getMagicEventSelectors(hexVersionId)
    .selectById(store.getState(), magicEventId);
  if (!magicEvent) {
    throw new Error("Can't update a magic event that doesn't exist");
  }

  // temporary to avoid ts warnings until we remove chainSummary
  if (key === "chainSummary") return;

  store.dispatch(
    hexVersionMPActions.setMagicEventField({
      hexVersionId,
      data: {
        entityId: magicEventId,
        key,
        value,
      },
    }),
  );
}

export function getAcceptMagicEventOps({
  hexVersionId,
  magicEventId,
  state,
}: {
  magicEventId: MagicEventId;
  state: RootState;
  hexVersionId: HexVersionId;
}): HexVersionAtomicOperation[] {
  // get all child magic events of parent that we want to accept
  const allMagicEvents = typedObjectValues(
    hexVersionMPSelectors
      .getMagicEventSelectors(hexVersionId)
      .selectEntities(state) ?? {},
  ).filter(notEmpty);

  const childEvents = allMagicEvents.filter(
    (e) => e.parentEventId === magicEventId,
  );

  return [
    ...childEvents.flatMap((e) => {
      return [
        UPDATE_MAGIC_EVENT.create(e.id, "accepted", true),
        UPDATE_MAGIC_EVENT.create(e.id, "acceptType", MagicAcceptType.KEEP),
        UPDATE_MAGIC_EVENT.create(e.id, "status", MagicEventStatus.REVIEWED),
      ];
    }),
    UPDATE_MAGIC_EVENT.create(magicEventId, "accepted", true),
    UPDATE_MAGIC_EVENT.create(magicEventId, "acceptType", MagicAcceptType.KEEP),
    UPDATE_MAGIC_EVENT.create(
      magicEventId,
      "status",
      MagicEventStatus.REVIEWED,
    ),
  ];
}
