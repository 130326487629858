import {
  Classes,
  InputGroup,
  InputGroupProps2,
  NumericInput,
  NumericInputProps,
  TagInput,
  TextArea,
} from "@blueprintjs/core";
import React from "react";
import styled, { css } from "styled-components";

import { WarningIcon } from "../components/icons/CustomIcons";

import { tagStyles } from "./HexTag.js";
import { HexTooltip } from "./HexTooltip";

/**
 * Styles for input components, but without targeting the input class itself
 */
export const inputStyleRules = css`
  color: ${({ theme }) => theme.fontColor.DEFAULT};

  background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.INPUT};
  height: 28px;
  min-height: 28px;

  transition: box-shadow
    ${({ theme }) => `${theme.animation.duration} ${theme.animation.easing}`};

  appearance: none;

  &:focus,
  &.${Classes.ACTIVE} {
    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
    box-shadow: ${({ theme }) => theme.boxShadow.INPUT_FOCUS};
  }

  &::placeholder {
    color: ${({ theme }) => theme.fontColor.PLACEHOLDER};
  }

  &.${Classes.DISABLED}, &:disabled {
    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
    box-shadow: ${({ theme }) => theme.boxShadow.INPUT};

    ${({ theme }) => theme.disabledState}
  }

  .${Classes.INPUT_GHOST} {
    color: ${({ theme }) => theme.fontColor.DEFAULT};
  }

  && .${Classes.MINIMAL} {
    color: ${({ theme }) => theme.fontColor.DEFAULT};
  }

  &.${Classes.LARGE} {
    min-width: 36px;
    min-height: 36px;

    .${Classes.INPUT_GHOST} {
      line-height: 28px;
    }
  }
`;

/**
 * Styles for input components that also require the input class
 */
export const inputStyles = css`
  &.${Classes.INPUT} {
    ${inputStyleRules}
  }
`;

export const smallInputStyles = css`
  height: 24px;
  min-height: 24px;

  &.${Classes.INPUT}, .${Classes.INPUT} {
    height: 24px;
    min-height: 24px;

    font-size: ${({ theme }) => theme.fontSize.SMALL};
    line-height: 16px;

    &:not(:first-child) {
      padding-left: 24px;
    }
  }
`;

export const largeInputStyles = css`
  height: 36px;

  .${Classes.INPUT} {
    height: 36px;
    min-height: 36px;

    font-size: ${({ theme }) => theme.fontSize.LARGE};
    line-height: 20px;

    &:not(:first-child) {
      padding-left: 36px;
    }
  }

  .${Classes.ICON} {
    margin: 10px;
  }
`;

export const HexInput = styled.input<{ $small?: boolean }>`
  ${inputStyles}

  ${({ $small }) => $small && smallInputStyles}
`;

type HexNumericInputExtraProps = {
  $small?: boolean;
  $inputWidth?: string;
};

export const HexNumericInput = styled(NumericInput)<
  HexNumericInputExtraProps & NumericInputProps
>`
  &&.${Classes.CONTROL_GROUP}:not(.${Classes.VERTICAL}) > :not(:last-child) {
    margin-right: -1px;
  }

  &&:not(.${Classes.INTENT_DANGER}) {
    .${Classes.INPUT_GROUP} {
      input {
        ${inputStyles}
        ${({ $small }) => $small && smallInputStyles}

        ${({ $small }) =>
          $small &&
          css`
            padding-right: 0;
            padding-left: 6px;
          `}
      }
    }
    .${Classes.BUTTON_GROUP} {
      position: relative;
      height: ${({ $small }) => ($small ? "24px" : "28px")};
      padding: 1px;
      overflow: hidden;

      background: ${({ theme }) => theme.backgroundColor.DEFAULT};

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -1px;
        z-index: 10;

        display: block;

        box-shadow: ${({ theme }) => theme.boxShadow.BUTTON};

        content: "";
        pointer-events: none;
      }

      .${Classes.BUTTON} {
        width: ${({ $small }) => ($small ? "20px" : "26px")};
        min-width: ${({ $small }) => ($small ? "20px" : "26px")};

        background: none;
        box-shadow: none;

        transition: background-color
          ${({ theme }) =>
            `${theme.animation.duration} ${theme.animation.easing}`};

        &:hover {
          background-color: ${({ theme }) => theme.hoverColor};
        }

        &:active {
          background-color: ${({ theme }) => theme.activeColor};
        }

        &.${Classes.DISABLED} {
          ${({ theme }) => theme.disabledState}
        }

        .${Classes.ICON} {
          color: ${({ theme }) => theme.iconColor};
        }
      }
    }
  }

  ${({ $inputWidth }) =>
    $inputWidth &&
    css`
      && input {
        width: ${$inputWidth};
      }
    `}
`;

/**
 * https://blueprintjs.com/docs/#core/components/text-inputs.input-group
 */
export const HexInputGroup = styled(InputGroup)`
  &.${Classes.DISABLED} {
    opacity: 0.5;
  }

  .${Classes.ICON} {
    pointer-events: none;
    margin: 6px;
  }

  input {
    ${inputStyles}
  }

  &.${Classes.SMALL} {
    ${smallInputStyles}
  }

  &.${Classes.LARGE} {
    ${largeInputStyles}
  }
`;

export const tagInputValuesStyles = css`
  .${Classes.TAG_INPUT_VALUES} {
    gap: 3px;
    align-content: flex-start;
    margin: 0;
    padding: 4px 28px 4px 4px;

    > span {
      margin: 0;
    }

    .${Classes.TAG} {
      ${tagStyles}
    }

    /* Hide the bp svg, and then add our own svg code as a background image */
    button > span.${Classes.ICON} {
      background-color: ${({ theme }) => theme.iconColor};
      background-image: none;
      mask-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m7.293 8.5-4.01-4.01.707-.708L8 7.792l4.01-4.01.707.708-4.01 4.01 4.01 4.01-.707.707L8 9.207l-4.01 4.01-.708-.707 4.01-4.01Z" fill="red"/></svg>');

      svg {
        visibility: hidden;
      }
    }

    .${Classes.INPUT_GHOST} {
      margin: 0;

      color: ${({ theme }) => theme.fontColor.DEFAULT};
    }
  }
`;

export const HexTagInput = styled(TagInput)`
  ${inputStyles};
  ${tagInputValuesStyles};

  &.${Classes.INPUT} {
    padding-left: 0;
    height: auto;
  }

  .${Classes.BUTTON} {
    margin: 2px;
  }
`;

export const HexTextArea = styled(TextArea)`
  ${inputStyles}

  &.${Classes.INPUT} {
    min-height: 60px;
    padding: 10px;

    border: none;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;

export type HexInputGroupWithErrorTooltipProps = InputGroupProps2 & {
  error?: string;
};

export const HexInputGroupWithErrorTooltip = React.memo(
  function HexInputGroupWithErrorTooltip(
    props: HexInputGroupWithErrorTooltipProps,
  ) {
    const { error, ...rest } = props;
    const tooltip =
      error != null ? (
        <HexTooltip
          autoFocus={false}
          content={error}
          enforceFocus={false}
          placement="right"
        >
          <WarningIcon
            css={`
              margin-right: 7px;
              margin-top: 7px;
            `}
            intent="danger"
          />
        </HexTooltip>
      ) : undefined;
    return (
      <HexInputGroup
        intent={error != null ? "danger" : undefined}
        rightElement={tooltip}
        {...rest}
      />
    );
  },
);
