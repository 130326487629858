import { CellId } from "@hex/common";
import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  CellContentsMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";
import {
  DisplayTableCellMpFragment,
  InputCellMpFragment,
  SqlCellMpFragment,
} from "../HexVersionMPModel.generated";

export type SqlCellMP = SqlCellMpFragment & {
  cellId: CellId;
};
export type InputCellMP = InputCellMpFragment & {
  cellId: CellId;
};
export type DisplayTableCellMP = DisplayTableCellMpFragment & {
  cellId: CellId;
};

export type UseCellContentsSelectorArgs<T> =
  | {
      cellId: CellId;
      selector: (cellContents: CellContentsMP) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe?: false;
    }
  | {
      cellId: CellId | undefined;
      selector: (cellContents: CellContentsMP | undefined) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe: true;
    };

export function useCellContentsSelector<T>(
  args: UseCellContentsSelectorArgs<T>,
): T {
  const { hexVersionId } =
    useProjectContext({ safe: args.safe ?? false }) ?? {};

  return useSelector((state) => {
    if (args.cellId == null) {
      if (args.safe) {
        return args.selector(undefined);
      } else {
        throw new Error(`Missing content state for cell: ${args.cellId}`);
      }
    }

    const cellContentsState =
      hexVersionId != null
        ? hexVersionMPSelectors
            .getCellContentSelectors(hexVersionId)
            .selectByCellId(state, args.cellId) ?? undefined
        : undefined;

    if (cellContentsState == null) {
      if (args.safe) {
        return args.selector(undefined);
      } else {
        throw new Error(`Missing content state for cell: ${args.cellId}`);
      }
    }

    return args.selector(cellContentsState);
  }, args.equalityFn);
}

type SafeCellContentsMP<S extends boolean> = S extends false
  ? CellContentsMP
  : CellContentsMP | undefined;

export type UseCellContentsGetterArgs<
  ARGS extends unknown[],
  SAFE extends boolean,
  T,
> = {
  selector?: (cellContents: SafeCellContentsMP<SAFE>, ...args: ARGS) => T;
  /**
   * @default false
   */
  safe?: SAFE;
};

export type UseCellContentsGetterResult<
  ARGS extends unknown[],
  SAFE extends boolean,
  T,
> = (
  cellId: SAFE extends false ? CellId : CellId | undefined,
  ...args: ARGS
) => T;

export function useCellContentsGetter<
  ARGS extends unknown[] = [],
  SAFE extends boolean = false,
  T = SafeCellContentsMP<SAFE>,
>(
  args: UseCellContentsGetterArgs<ARGS, SAFE, T> = {},
): UseCellContentsGetterResult<ARGS, SAFE, T> {
  const { hexVersionId } =
    useProjectContext({ safe: args.safe ?? false }) ?? {};
  const store = useStore();
  const selectorRef = useStableRef(args.selector);

  return useCallback(
    (cellId: CellId | undefined, ...cbArgs: ARGS): T => {
      if (cellId == null) {
        if (args.safe) {
          const selector = selectorRef.current;
          return selector?.(
            undefined as SafeCellContentsMP<SAFE>,
            ...cbArgs,
          ) as T;
        } else {
          throw new Error(`Missing content state for cell: ${cellId}`);
        }
      }

      const cellContents =
        hexVersionId != null
          ? hexVersionMPSelectors
              .getCellContentSelectors(hexVersionId)
              .selectByCellId(store.getState(), cellId)
          : undefined;

      if (cellContents == null) {
        if (args.safe) {
          const selector = selectorRef.current;
          return selector?.(
            undefined as SafeCellContentsMP<SAFE>,
            ...cbArgs,
          ) as T;
        } else {
          throw new Error(`Missing content state for cell: ${cellId}`);
        }
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      const selector = selectorRef.current;
      return selector ? selector(cellContents, ...cbArgs) : (cellContents as T);
    },
    [hexVersionId, store, selectorRef, args.safe],
  );
}
