import { CellId } from "@hex/common";
import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  CellContentsMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";

export interface UseCellsContentsSelectorArgs<T> {
  selector: (cells: Record<CellId, CellContentsMP | undefined>) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useCellsContentsSelector<T>({
  equalityFn,
  selector,
}: UseCellsContentsSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const cellsState = hexVersionMPSelectors
      .getCellContentSelectors(hexVersionId)
      .selectEntities(state);

    if (cellsState == null) {
      throw new Error(`Missing cells state for hex version: ${hexVersionId}`);
    }

    return selector(cellsState);
  }, equalityFn);
}

export interface UseCellsContentsGetterArgs<A extends unknown[], T> {
  selector?: (
    cells: Record<CellId, CellContentsMP | undefined>,
    ...args: A
  ) => T;
}

export type UseCellsContentsGetterResult<A extends unknown[], T> = (
  ...args: A
) => T;

export function useCellsContentsGetter<
  A extends unknown[],
  T = Record<CellId, CellContentsMP | undefined>,
>({
  selector,
}: UseCellsContentsGetterArgs<A, T> = {}): UseCellsContentsGetterResult<A, T> {
  const { hexVersionId } = useProjectContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const cells = hexVersionMPSelectors
        .getCellContentSelectors(hexVersionId)
        .selectEntities(store.getState());

      if (cells == null) {
        throw new Error(`Missing cells state for hex version: ${hexVersionId}`);
      }

      // this cannot be conditionally chained/nullish coleased since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(cells, ...args)
        : (cells as unknown as T);
    },
    [hexVersionId, store, selectorRef],
  );
}
