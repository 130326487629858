import { Classes, Intent, MaybeElement, TooltipProps } from "@blueprintjs/core";
import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { HexAnchorButton } from "./HexButton";
import { HexTooltip } from "./HexTooltip";

type RichSwitchType = "view-toggle" | "data-select";

const Container = styled.div<{
  $minimal: boolean;
  $fill: boolean;
  $showBkg: boolean;
  $dataSelect: boolean;
  $equalWidths: boolean;
}>`
  ${({ $equalWidths }) =>
    $equalWidths
      ? `display: grid;
         grid-auto-columns: minmax(0, 1fr);
         grid-auto-flow: column;`
      : `display: flex;`}

  ${({ $fill }) => $fill && "flex: auto;"}

  align-items: center;

  box-shadow: ${({ $dataSelect, $minimal, theme }) =>
    $dataSelect && !$minimal
      ? `inset 0 0 0 1px ${theme.borderColor.DEFAULT}`
      : "none"};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ $showBkg, theme }) =>
    $showBkg &&
    css`
      background: ${theme.backgroundColor.DARK};
    `}
`;

const StyledHexAnchorButton = styled(HexAnchorButton)<{ $isActive: boolean }>`
  .${Classes.BUTTON_TEXT} {
    color: ${({ $isActive, theme }) =>
      !$isActive && `${theme.fontColor.MUTED}`};
  }
  .${Classes.ICON} {
    opacity: ${({ $isActive }) => !$isActive && `0.6`};
  }
  height: 100%;
`;

const FlexibleWidthOptionWrapper = styled.div`
  flex: auto;
  height: 100%;
`;
const EqualWidthOptionWrapper = styled.div`
  height: 100%;
`;

const StyledHexTooltip = styled(HexTooltip)`
  width: 100%;
  height: 100%;
`;

export interface RichSwitchOption {
  id: string;
  text?: ReactNode;
  active: boolean;
  "data-cy"?: string;
  disabled?: boolean;
  tooltip?: TooltipProps;
  icon?: MaybeElement;
  onClick: () => void;
}

export interface HexRichSwitchProps {
  className?: string;
  fill?: boolean;
  minimal?: boolean;
  options: RichSwitchOption[];
  small?: boolean;
  type?: RichSwitchType;
  buttonIntent?: Intent;
  equalWidthOptions?: boolean;
}

export const HexRichSwitch: React.ComponentType<HexRichSwitchProps> =
  React.memo(function HexRichSwitch({
    buttonIntent = "primary",
    className,
    equalWidthOptions = false,
    fill = true,
    minimal = false,
    options,
    small = false,
    type = "view-toggle",
  }) {
    const OptionWrapper = equalWidthOptions
      ? EqualWidthOptionWrapper
      : FlexibleWidthOptionWrapper;
    return (
      <Container
        $dataSelect={type === "data-select"}
        $equalWidths={equalWidthOptions}
        $fill={fill}
        $minimal={minimal}
        $showBkg={!minimal && type === "view-toggle"}
        className={className}
      >
        {options.map((item) => {
          const element = (
            <StyledHexAnchorButton
              key={item.id}
              $isActive={item.active}
              data-cy={item["data-cy"]}
              disabled={item.disabled}
              fill={fill}
              icon={item.icon}
              id={item.id}
              intent={
                type === "data-select" && item.active ? buttonIntent : undefined
              }
              minimal={!item.active}
              small={small}
              text={item.text}
              onClick={item.onClick}
            />
          );

          if (item.tooltip) {
            return (
              <OptionWrapper key={item.id}>
                <StyledHexTooltip placement="top" {...item.tooltip}>
                  {element}
                </StyledHexTooltip>
              </OptionWrapper>
            );
          }

          return <OptionWrapper key={item.id}>{element}</OptionWrapper>;
        })}
      </Container>
    );
  });
