import { CellId, StaticCellId } from "@hex/common";
import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  CellMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";

export type UseCellSelectorArgs<T> =
  | {
      cellId: CellId;
      selector: (cell: CellMP) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe?: false;
    }
  | {
      cellId: CellId | undefined;
      selector: (cell: CellMP | undefined) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe: true;
    };

export function useCellSelector<T>(args: UseCellSelectorArgs<T>): T {
  const { hexVersionId } =
    useProjectContext({ safe: args.safe ?? false }) ?? {};

  return useSelector((state) => {
    if (args.cellId == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(`Missing state for cell: ${args.cellId}`);
      }
    }
    const cellState =
      hexVersionId != null
        ? hexVersionMPSelectors
            .getCellSelectors(hexVersionId)
            .selectById(state, args.cellId) ?? undefined
        : undefined;

    if (cellState == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(`Missing state for cell: ${args.cellId}`);
      }
    }

    const selector = args.selector;
    return selector(cellState);
  }, args.equalityFn);
}

export type UseStaticCellSelectorArgs<T> =
  | {
      staticCellId: StaticCellId;
      selector: (cell: CellMP) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe?: false;
    }
  | {
      staticCellId: StaticCellId | undefined;
      selector: (cell: CellMP | undefined) => T;
      equalityFn?: (left: T, right: T) => boolean;
      safe: true;
    };

export function useStaticCellSelector<T>(
  args: UseStaticCellSelectorArgs<T>,
): T {
  const { hexVersionId } =
    useProjectContext({ safe: args.safe ?? false }) ?? {};

  return useSelector((state) => {
    if (args.staticCellId == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(`Missing state for static cell: ${args.staticCellId}`);
      }
    }

    const cellState =
      hexVersionId != null
        ? hexVersionMPSelectors
            .getCellSelectors(hexVersionId)
            .selectByStaticId(state, args.staticCellId) ?? undefined
        : undefined;

    if (cellState == null) {
      if (args.safe) {
        const selector = args.selector;
        return selector(undefined);
      } else {
        throw new Error(`Missing state for staic cell: ${args.staticCellId}`);
      }
    }

    const selector = args.selector;
    return selector(cellState);
  }, args.equalityFn);
}

type SafeCellMP<S extends boolean> = S extends false
  ? CellMP
  : CellMP | undefined;

export type UseCellGetterArgs<
  ARGS extends unknown[],
  SAFE extends boolean,
  T,
> = {
  selector?: (cell: SafeCellMP<SAFE>, ...args: ARGS) => T;
  /**
   * @default false
   */
  safe?: SAFE;
};

export type UseCellGetterResult<
  ARGS extends unknown[],
  SAFE extends boolean,
  T,
> = (
  cellId: SAFE extends false ? CellId : CellId | undefined,
  ...args: ARGS
) => T;

export function useCellGetter<
  ARGS extends unknown[] = [],
  SAFE extends boolean = false,
  T = SafeCellMP<SAFE>,
>(
  args: UseCellGetterArgs<ARGS, SAFE, T> = {},
): UseCellGetterResult<ARGS, SAFE, T> {
  const store = useStore();
  const { hexVersionId } =
    useProjectContext({ safe: args.safe ?? false }) ?? {};
  const selectorRef = useStableRef(args.selector);

  return useCallback(
    (cellId: CellId | undefined, ...cbArgs: ARGS): T => {
      if (cellId == null) {
        if (args.safe) {
          const selector = selectorRef.current;
          return selector?.(undefined as SafeCellMP<SAFE>, ...cbArgs) as T;
        } else {
          throw new Error(`Missing state for cell: ${cellId}`);
        }
      }

      const cell =
        hexVersionId != null
          ? hexVersionMPSelectors
              .getCellSelectors(hexVersionId)
              .selectById(store.getState(), cellId)
          : undefined;

      if (cell == null) {
        if (args.safe) {
          const selector = selectorRef.current;
          return selector?.(undefined as SafeCellMP<SAFE>, ...cbArgs) as T;
        } else {
          throw new Error(`Missing state for cell: ${cellId}`);
        }
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      const selector = selectorRef.current;
      return selector ? selector(cell, ...cbArgs) : (cell as T);
    },
    [args.safe, hexVersionId, selectorRef, store],
  );
}
