import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import { HexMP, hexMPSelectors } from "../../redux/slices/hexMPSlice.js";
import { useProjectContext } from "../../util/projectContext";

export interface UseHexSelectorArgs<T> {
  selector: (hex: HexMP) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useHexSelector<T>({
  equalityFn,
  selector,
}: UseHexSelectorArgs<T>): T {
  const { hexId } = useProjectContext();

  return useSelector((state) => {
    const hex = hexMPSelectors.getHexSelectors(hexId).select(state);

    return selector(hex);
  }, equalityFn);
}

export interface UseHexGetterArgs<A extends unknown[], T> {
  selector?: (hex: HexMP, ...args: A) => T;
}

export type UseHexGetterResult<A extends unknown[], T> = (...args: A) => T;

export function useHexGetter<A extends unknown[], T = HexMP>({
  selector,
}: UseHexGetterArgs<A, T> = {}): UseHexGetterResult<A, T> {
  const { hexId } = useProjectContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const hex = hexMPSelectors
        .getHexSelectors(hexId)
        .select(store.getState());

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(hex, ...args)
        : (hex as unknown as T);
    },
    [hexId, store, selectorRef],
  );
}
