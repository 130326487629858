import { HexAtomicOperation } from "@hex/common";
import { createContext, useContext } from "react";

import { AtomicOperationController } from "../atomic-operations";

const throwUninitialized = (): never => {
  throw new Error("AO context must be initialized!");
};

const dummyAtomicOperationController: AtomicOperationController<HexAtomicOperation> =
  {
    dispatchAO: throwUninitialized,
    undoAO: throwUninitialized,
    redoAO: throwUninitialized,
    subscribeToStatus: throwUninitialized,
    getCurrentStatus: throwUninitialized,
    destroy: throwUninitialized,
    processQueuedOperations: throwUninitialized,
    getStats: throwUninitialized,
  };

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const HexAOContextInternal = createContext<
  AtomicOperationController<HexAtomicOperation>
>(dummyAtomicOperationController);
HexAOContextInternal.displayName = "HexAOContext";

export const HexAOContext = HexAOContextInternal.Provider;

export function useHexAOContext(): AtomicOperationController<HexAtomicOperation> {
  return useContext(HexAOContextInternal);
}
