/* eslint-disable no-restricted-properties */
import { Checkbox, CheckboxProps, Classes } from "@blueprintjs/core";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

export type HexCheckboxProps = CheckboxProps & {
  small?: boolean;
};

/**
 * https://blueprintjs.com/docs/#core/components/checkbox
 */
const StyledCheckbox = styled(Checkbox)<{
  $small?: boolean;
}>`
  &&&& {
    margin: 0;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-size: ${({ theme }) => theme.fontSize.DEFAULT};

    ${({ $small, theme }) => $small && `font-size: ${theme.fontSize.SMALL};`}

    &:hover > .${Classes.CONTROL_INDICATOR} {
      background-color: ${({ theme }) => theme.hoverColor};
    }

    /* BOX STYLES - non-indeterminate */
    input ~ .${Classes.CONTROL_INDICATOR} {
      margin-right: 7px;

      background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};

      border-radius: ${({ theme }) => theme.borderRadius};
      background-image: none;
      box-shadow: ${({ theme }) => theme.boxShadow.BUTTON};

      transition:
        background-color ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing},
        box-shadow ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};
    }

    /* BOX STYLES - non-indeterminate */
    input:not(:indeterminate) ~ .${Classes.CONTROL_INDICATOR} {
      &::before {
        opacity: 0;
        background-color: ${({ theme }) => theme.intent.PRIMARY};
        background-image: none;
        mask-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.2659 4.35687L6.93715 12.2679L2.73401 8.18033L3.43119 7.46343L6.84452 10.7829L12.4851 3.73218L13.2659 4.35687Z" fill="red"/></svg>');
        transition: opacity ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};
      }
    }

    /* BOX STYLES - indeterminate */
    input:indeterminate ~ .${Classes.CONTROL_INDICATOR} {
      &::before {
        opacity: 0;
        background-color: ${({ theme }) => theme.intent.PRIMARY};
        background-image: none;
        mask-image: url('data:image/svg+xml,<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 8.5H3v-1h10v1Z" fill="red"/></svg>');
        transition: opacity ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};
      }
    }

    /* CHECKED */
    input:checked
      ~ .${Classes.CONTROL_INDICATOR},
      input:indeterminate
      ~ .${Classes.CONTROL_INDICATOR} {
      background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.15)};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.intent.PRIMARY};

      &::before {
        opacity: 1;
      }
    }

    /* HOVERING, NOT DISABLED */
    &:not(.${Classes.DISABLED}):hover {
      /* NOT CHECKED */
      input ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${({ theme }) => theme.hoverColor};
      }

      /* CHECKED */
      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.25)};
      }
    }

    /* ACTIVE, NOT DISABLED */
    &:not(.${Classes.DISABLED}):active {
      /* NOT CHECKED */
      input ~ .${Classes.CONTROL_INDICATOR} {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.borderColor.DEFAULT};
      }

      /* CHECKED */
      input:checked
        ~ .${Classes.CONTROL_INDICATOR},
        input:indeterminate
        ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.3)};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.intent.PRIMARY};
      }
    }

    /* DISABLED */
    &.${Classes.DISABLED} {
      ${({ theme }) => theme.disabledState}
    }
  }
`;

export const HexCheckbox: React.ComponentType<HexCheckboxProps> = React.memo(
  function HexCheckbox({ small, ...props }) {
    return <StyledCheckbox $small={small} {...props} />;
  },
);
