// eslint-disable-next-line no-restricted-imports -- we need BP Text here and here only as we use it for it's ellipsize prop!
import { Text as BPText } from "@blueprintjs/core";
import React from "react";
import styled, { IntrinsicElementsKeys, css } from "styled-components";

import { Theme } from "../theme/common/theme.js";

export type FontSize = Lowercase<
  "EXTRA_SMALL" | "SMALL" | "DEFAULT" | "LARGE" | "EXTRA_LARGE"
>;
export type FontColor = Lowercase<keyof Theme["fontColor"]>;
export type FontWeight = Lowercase<keyof Theme["fontWeight"]>;

interface TxtInternalProps {
  $fontSize: FontSize | undefined;
  $fontColor: FontColor | undefined;
  $fontWeight: FontWeight | undefined;
}

export interface TxtProps {
  /** optional: the semantic html element to render as*/
  renderAs?: IntrinsicElementsKeys;
  fontSize?: FontSize;
  fontColor?: FontColor;
  fontWeight?: FontWeight;
  ellipsize?: boolean;

  children?: React.ReactNode;

  // Extra standard props to pass straight through
  className?: string;
  title?: string;
  onClick?: React.MouseEventHandler;
}

export const Txt = React.forwardRef<HTMLElement, TxtProps>(function Text(
  {
    children,
    ellipsize = false,
    fontColor,
    fontSize,
    fontWeight,
    renderAs,
    ...rest
  }: TxtProps,
  ref,
) {
  return ellipsize ? (
    // We switch to blueprint's text component only if you want to use the ellipses prop since it's a more expensive component to render
    <StyledBPText
      // TODO need to be on an version of blueprint that includes Mac's change to include ref here: https://github.com/palantir/blueprint/pull/6331
      // ref={ref}
      $fontColor={fontColor}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      ellipsize={true}
      // Cannot ellipses on an inline element like span https://semicolon.dev/tutorial/css/text-overflow-ellipsis-doesnt-work
      // We only use this component when ellipsize = true, so default to div instead of span
      tagName={renderAs ?? "div"}
      {...rest}
    >
      {children}
    </StyledBPText>
  ) : (
    <TxtInternal
      ref={ref}
      $fontColor={fontColor}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      as={renderAs ?? "span"}
      {...rest}
    >
      {children}
    </TxtInternal>
  );
});

const uppercase = <T extends string>(str: T): Uppercase<T> =>
  str.toUpperCase() as Uppercase<T>;

const SharedCSS = css<TxtInternalProps>`
  font-size: ${({ $fontSize, theme }) =>
    $fontSize ? theme.fontSize[uppercase($fontSize)] : "inherit"};
  color: ${({ $fontColor, theme }) =>
    $fontColor ? theme.fontColor[uppercase($fontColor)] : "inherit"};
  font-weight: ${({ $fontWeight, theme }) =>
    $fontWeight ? theme.fontWeight[uppercase($fontWeight)] : "inherit"};
  /* Line-height is not configurable on this component - always in lockstep with fontSize */
  line-height: ${({ $fontSize, theme }) =>
    $fontSize ? theme.lineHeight[uppercase($fontSize)] : "inherit"};
`;

const TxtInternal = styled.span`
  ${SharedCSS}
`;

const StyledBPText = styled(BPText)`
  ${SharedCSS}
`;
