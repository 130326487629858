import styled, { css } from "styled-components";

/**
 * Screen reader only styles
 *
 * These styles visually hide text while still making it accessible to screen readers.
 * By positioning it off-screen it remains available for users relying on screen readers.
 */
export const srOnlyStyles = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

/**
 * Screen reader only element
 *
 * Hides any text/children while still making them accessible to screen readers.
 */
export const SROnly = styled.span`
  ${srOnlyStyles}
`;
