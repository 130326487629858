import { Classes, Tag, TagProps } from "@blueprintjs/core";
import { lighten, rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components";

export const tagStyles = css<{ $small?: boolean }>`
  &.${Classes.TAG}.${Classes.MINIMAL} {
    color: ${({ theme }) => theme.fontColor.DEFAULT};

    background-color: ${({ theme }) =>
      rgba(lighten(0.1, theme.fontColor.DEFAULT), 0.08)};

    &.${Classes.INTENT_PRIMARY} {
      color: ${({ theme }) => theme.intent.PRIMARY};

      background-color: ${({ theme }) =>
        rgba(lighten(0.1, theme.intent.PRIMARY), 0.12)};

      .${Classes.ICON} > svg {
        color: ${({ theme }) => theme.intent.PRIMARY};
      }
    }

    &.${Classes.INTENT_SUCCESS} {
      color: ${({ theme }) => theme.intent.SUCCESS};

      background-color: ${({ theme }) =>
        rgba(lighten(0.1, theme.intent.SUCCESS), 0.12)};

      .${Classes.ICON} > svg {
        color: ${({ theme }) => theme.intent.SUCCESS};
      }
    }

    &.${Classes.INTENT_WARNING} {
      color: ${({ theme }) => theme.intent.WARNING};

      background-color: ${({ theme }) =>
        rgba(lighten(0.1, theme.intent.WARNING), 0.12)};

      .${Classes.ICON} > svg {
        color: ${({ theme }) => theme.intent.WARNING};
      }
    }

    &.${Classes.INTENT_DANGER} {
      color: ${({ theme }) => theme.intent.DANGER};

      background-color: ${({ theme }) =>
        rgba(lighten(0.1, theme.intent.DANGER), 0.12)};

      .${Classes.ICON} > svg {
        color: ${({ theme }) => theme.intent.DANGER};
      }
    }

    ${({ $small }) =>
      $small &&
      css`
        font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
        line-height: 16px;
        height: 16px;
        min-height: 16px;
        padding: 0px 4px;
      `}
  }
`;

const StyledTag = styled(Tag)<{ $small?: boolean }>`
  ${tagStyles}
`;

/**
 * https://blueprintjs.com/docs/#core/components/tag
 */

interface HexTagUniqueProps {
  small?: boolean;
}

export interface HexTagProps extends TagProps, HexTagUniqueProps {}

export const HexTag: React.ComponentType<HexTagProps> = ({
  small,
  ...props
}) => <StyledTag $small={small} {...props} minimal={true} />;
