import { Classes } from "@blueprintjs/core";
import { Suggest2 } from "@blueprintjs/select";
import styled from "styled-components";

import { inputStyles } from "./HexInput";

// HACKHACKHACK
// Styled components doesn't properly pass forward component type parameters: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33250
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const typedStyled: <T>(c: T) => (...a: any[]) => T = styled;

// todo(CORE-4851): Add to Spellbook
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
export const HexSuggest2 = typedStyled(Suggest2)`
  .${Classes.INPUT} {
    ${inputStyles};

    > .${Classes.ICON} {
      margin: 6px 1px 0 4px;
    }
  }
`;
