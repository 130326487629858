import { Classes } from "@blueprintjs/core";
import { MultiSelect, MultiSelect2 } from "@blueprintjs/select";
import styled, { css } from "styled-components";

import { inputStyles, tagInputValuesStyles } from "./HexInput";

// HACKHACKHACK
// Styled components doesn't properly pass forward component type parameters: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33250
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- above
const typedStyled: <T>(c: T) => (...a: any[]) => T = styled;

export const multiSelectStyles = css`
  &.${Classes.INPUT} {
    position: relative;
    height: auto;
    max-height: 155px;
    padding-left: 0;
    overflow: auto;

    > .${Classes.ICON} {
      margin: 6px 2px 6px 8px;
    }

    ${tagInputValuesStyles};
  }
`;

// Please see HexMultiSelect2 below to see if it would better satisfy need!
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- above
export const HexMultiSelect = typedStyled(MultiSelect)`
  .${Classes.INPUT} {
    ${inputStyles}
    ${multiSelectStyles}

    .${Classes.BUTTON} {
      position: sticky;
      top: 3px;
      right: 3px;
      margin: 0;
    }
  }
`;

/** MultiSelect is deprecated in favor of MultiSelect2. See component migration guide here: https://github.com/palantir/blueprint/wiki/select-component-migration
 * Some highlighted benefits of why we added MultiSelect2:
 * 1) matchTargetWidth prop - you want the popover to take the entire width of parent
 * 2) rootBoundary > boundary - better defines the popover's placement relative to the select component.
 */
// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization -- see above
export const HexMultiSelect2 = typedStyled(MultiSelect2)`
  .${Classes.INPUT} {
    ${inputStyles}
    ${multiSelectStyles}

    .${Classes.BUTTON} {
      position: sticky;
      top: 3px;
      right: 3px;
      margin: 0;
    }
  }
`;
