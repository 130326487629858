import {
  DataConnectionHexVersionLinkId,
  DataConnectionLinkType,
  notEmpty,
} from "@hex/common";
import { useCallback } from "react";
import { createSelector } from "reselect";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  DataConnectionHexVersionLinkMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";

export interface UseDataConnectionHexVersionLinksSelectorArgs<T> {
  selector: (
    dataConnectionHexVersionLink: Record<
      DataConnectionHexVersionLinkId,
      DataConnectionHexVersionLinkMP | undefined
    >,
  ) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useDataConnectionHexVersionLinksSelector<T>({
  equalityFn,
  selector,
}: UseDataConnectionHexVersionLinksSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const dataConnectionHexVersionLinksState = hexVersionMPSelectors
      .getDataConnectionHexVersionLinkSelectors(hexVersionId)
      .selectEntities(state);

    if (dataConnectionHexVersionLinksState == null) {
      throw new Error(
        `Missing dataConnectionHexVersionLink state for hex version: ${hexVersionId}`,
      );
    }

    return selector(dataConnectionHexVersionLinksState);
  }, equalityFn);
}

export interface UseDataConnectionHexVersionLinksGetterArgs<
  A extends unknown[],
  T,
> {
  selector?: (
    dataConnectionHexVersionLinks: Record<
      DataConnectionHexVersionLinkId,
      DataConnectionHexVersionLinkMP | undefined
    >,
    ...args: A
  ) => T;
}

export type UseDataConnectionHexVersionLinksGetterResult<
  A extends unknown[],
  T,
> = (...args: A) => T;

export function useDataConnectionHexVersionLinksGetter<
  A extends unknown[],
  T = Record<
    DataConnectionHexVersionLinkId,
    DataConnectionHexVersionLinkMP | undefined
  >,
>({
  selector,
}: UseDataConnectionHexVersionLinksGetterArgs<
  A,
  T
> = {}): UseDataConnectionHexVersionLinksGetterResult<A, T> {
  const { hexVersionId } = useProjectContext();
  const store = useStore();
  const selectorRef = useStableRef(selector);

  return useCallback(
    (...args: A) => {
      const dataConnectionHexVersionLinks = hexVersionMPSelectors
        .getDataConnectionHexVersionLinkSelectors(hexVersionId)
        .selectEntities(store.getState());

      if (dataConnectionHexVersionLinks == null) {
        throw new Error(
          `Missing dataConnectionHexVersionLinks state for hex version: ${hexVersionId}`,
        );
      }

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(dataConnectionHexVersionLinks, ...args)
        : (dataConnectionHexVersionLinks as unknown as T);
    },
    [hexVersionId, store, selectorRef],
  );
}

export const importedOrgDataConnectionIdsSelector = createSelector(
  (
    links: Record<
      DataConnectionHexVersionLinkId,
      DataConnectionHexVersionLinkMP | undefined
    >,
  ) => links,
  (links) =>
    Object.values(links)
      .filter(
        (link) =>
          link?.dataConnectionLinkType === DataConnectionLinkType.WORKSPACE,
      )
      .map((link) => link?.dataConnectionId)
      .filter(notEmpty),
);

export const importedDataConnectionIdsSetSelector = createSelector(
  importedOrgDataConnectionIdsSelector,
  (ids) => new Set(ids),
);
