import { useCallback } from "react";

import { useStableRef } from "../../hooks/useStableRef.js";
import { useSelector, useStore } from "../../redux/hooks";
import {
  HexVersionMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice";
import { useProjectContext } from "../../util/projectContext";

export interface UseHexVersionSelectorArgs<T> {
  selector: (hexVersion: HexVersionMP) => T;
  equalityFn?: (left: T, right: T) => boolean;
}

export function useHexVersionSelector<T>({
  equalityFn,
  selector,
}: UseHexVersionSelectorArgs<T>): T {
  const { hexVersionId } = useProjectContext();

  return useSelector((state) => {
    const hexVersion = hexVersionMPSelectors
      .getHexVersionSelectors(hexVersionId)
      .select(state);

    return selector(hexVersion);
  }, equalityFn);
}

export interface UseHexVersionGetterArgs<
  A extends unknown[],
  S extends boolean,
  T,
> {
  selector?: (hexVersion: HexVersionMP, ...args: A) => T;
  safe?: S;
}

export type UseHexVersionGetterResult<
  A extends unknown[],
  S,
  T,
> = S extends false ? (...args: A) => T : (...args: A) => T | undefined;

export function useHexVersionGetter<
  A extends unknown[],
  S extends boolean = false,
  T = HexVersionMP,
>({
  safe,
  selector,
}: UseHexVersionGetterArgs<A, S, T> = {}): UseHexVersionGetterResult<A, S, T> {
  const { hexVersionId } = useProjectContext(safe ? { safe } : {}) ?? {};
  const store = useStore();
  const selectorRef = useStableRef(selector);

  const baseFn = useCallback(
    (...args: A) => {
      const hexVersion = hexVersionMPSelectors
        // If not safe, useProjectContext will throw.
        .getHexVersionSelectors(hexVersionId!)
        .select(store.getState());

      // this cannot be conditionally chained/nullish coalesced since
      // the provided selector may intentionally return undefined
      return selectorRef.current
        ? selectorRef.current(hexVersion, ...args)
        : (hexVersion as unknown as T);
    },
    [hexVersionId, store, selectorRef],
  );

  const safeFn = useCallback(
    (...args: A) => {
      if (!hexVersionId) {
        return undefined;
      }
      return baseFn(...args);
    },
    [baseFn, hexVersionId],
  ) as UseHexVersionGetterResult<A, S, T>;

  return safe ? safeFn : baseFn;
}
