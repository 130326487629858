/* eslint-disable no-restricted-properties */
import { Classes, Switch, SwitchProps } from "@blueprintjs/core";
import { rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components";

interface HexSwitchUniqueProps {
  small?: boolean;
  verticalAlign?: "start" | "center";
}

/**
 * https://blueprintjs.com/docs/#core/components/switch
 */
const StyledSwitch = styled(Switch)<{
  $small?: boolean;
  $verticalAlign?: "start" | "center"; // defaults to "center"
}>`
  && {
    display: flex;
    align-items: ${({ $verticalAlign }) =>
      $verticalAlign === "start" ? "flex-start" : "center"};
    min-height: 14px;
    margin: 0;

    color: ${({ theme }) => theme.fontColor.DEFAULT};
    font-size: ${({ theme }) => theme.fontSize.DEFAULT};
    line-height: 1.3;

    ${({ $small, theme }) =>
      $small &&
      css`
        font-size: ${theme.fontSize.SMALL};
      `}

    &.${Classes.ALIGN_RIGHT} {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    input ~ .${Classes.CONTROL_INDICATOR} {
      flex: none;
      width: 26px;
      height: 14px;
      margin-top: 0;
      margin-right: 6px;

      background-color: transparent;

      border-radius: 7px;
      box-shadow: ${({ theme }) => theme.boxShadow.SWITCH} !important;

      transition:
        background-color ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing},
        box-shadow ${({ theme }) => theme.animation.duration}
          ${({ theme }) => theme.animation.easing};

      &::before {
        width: 14px;
        height: 14px;
        margin: 0;

        background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
        border-radius: 7px;
        box-shadow: ${({ theme }) => theme.boxShadow.SWITCH};

        transition:
          left ${({ theme }) => theme.animation.duration}
            ${({ theme }) => theme.animation.easing},
          opacity ${({ theme }) => theme.animation.duration}
            ${({ theme }) => theme.animation.easing},
          box-shadow ${({ theme }) => theme.animation.duration}
            ${({ theme }) => theme.animation.easing};
      }
    }

    input:checked ~ .${Classes.CONTROL_INDICATOR} {
      background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.2)};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.intent.PRIMARY} !important;

      &::before {
        left: 50%;

        background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.intent.PRIMARY};
        opacity: 1;
      }
    }

    &:not(.${Classes.DISABLED}):hover {
      input ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.1)};

        &::before {
          opacity: 1;
        }
      }

      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.3)};
      }
    }

    &.${Classes.DISABLED} {
      opacity: 0.45;

      input ~ .${Classes.CONTROL_INDICATOR} {
        background-color: transparent;
        box-shadow: ${({ theme }) => theme.boxShadow.SWITCH} !important;

        &::before {
          background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
          box-shadow: ${({ theme }) => theme.boxShadow.SWITCH};
          opacity: 1;
        }
      }

      input:checked ~ .${Classes.CONTROL_INDICATOR} {
        background-color: ${({ theme }) => rgba(theme.intent.PRIMARY, 0.2)};
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.intent.PRIMARY} !important;

        &::before {
          background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.intent.PRIMARY};
          opacity: 1;
        }
      }
    }
  }
`;

export interface HexSwitchProps extends SwitchProps, HexSwitchUniqueProps {}

export const HexSwitch: React.ComponentType<HexSwitchProps> = React.memo(
  function HexSwitch({ className, small, verticalAlign, ...props }) {
    return (
      <StyledSwitch
        $small={small}
        $verticalAlign={verticalAlign}
        className={className}
        {...props}
      />
    );
  },
);
